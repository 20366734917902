import type { RouteConfig } from 'vue-router'

import { ensureToken } from '@/router/guards/ensureToken'

export default [
  {
    path: '/onboarding',
    name: 'OnboardingCall',
    component: () => import(/* webpackChunkName: "onboarding-views" */ './views/ScheduleOnboarding.vue'),
    beforeEnter: ensureToken
  }
] as Array<RouteConfig>
